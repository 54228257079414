import logo from './logo.png';
import apple_store from './apple_store.svg';
import qrcode from './QRcode.png';
import './App.css';
import {useState} from "react";
import MobileStoreButton from 'react-mobile-store-button';

function App() {
  const [showQR, setShowQR] = useState(false);

  function renderQR() {
    if (!showQR) return (
        <div style={{marginTop: 30}}>
          <button className="show_qr_button" onClick={() => {
            setShowQR(true);
          }}>Show QR Code</button>
        </div>

    );
    return (
        <div>
            <p>
                Scan the QR below with your phone to download Cafe Depot:
            </p>
            <p>
                <img className="qr-link" src={qrcode}/>
            </p>
            <p>
                <button className="show_qr_button" onClick={() => {
                  setShowQR(false);
                }}>Show Download Links</button>
            </p>
        </div>
    );
  }

  function renderStoreLinks()
  {
    if (showQR) return <></>;
    return (
        <div>
          <p>
            Download Cafe Depot Order Companion below:
          </p>
          <p style={{width: '100%', textAlign: 'center', verticalAlign: 'middle'}}>
              <MobileStoreButton
                  store="android"
                  url={"https://play.google.com/store/apps/details?id=nz.co.cafedepot.mobile"}
                  width={200}
                  linkProps={{ title: 'Android Store Button' }}
              />
          </p>
          <p style={{width: '100%', textAlign: 'center', verticalAlign: 'middle'}}>
              <MobileStoreButton
                  store="ios"
                  width={200}
                  url={"https://apps.apple.com/us/app/cafe-depot-nz/id6443968440"}
                  linkProps={{ title: 'iOS Store Button' }}
              />
          </p>
        </div>
    );
  }

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="CafeDepot Logo" />
        {renderStoreLinks()}
        {renderQR()}
      </header>
    </div>
  );
}

export default App;
